/* You can add global styles to this file, and also import other style files */
/*@font-face {
  font-family: nexaboldFont;
  src: url("assets/Fontfabric - Nexa Bold.otf");
}

body {
  font-family: nexaboldFont;
}*/
/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  .row {
    margin-left: 0;
  }
}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  .container-fluid {
    min-height: 100%;
    background-color: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding-bottom: 30px;
  }

  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }

  .multiselect-dropdown .dropdown-btn {
    display: inline-block;
    border: 1px solid #00922f;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.1;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
  }

  .btn-primary {
    font-size: 13px;
    background-color: #00922f;
    border-radius: 2px;
  }

  .navbar-inverse {
    border-color: #ccc;
    background-color: #CCFFCC;
  }

  .logo {
    float: left;
  }

  .navbar-inverse .navbar-brand {
    float: left;
    height: 60px;
    padding: 10px 15px;
    font-size: 18px;
    line-height: 20px;
  }

  .login {
    float: right;
    margin-top: 14px;
    margin-right: 50px;
  }

  .navbar-nav {
    float: none;
  }

.title {
  font-size: 25px;
  color: #00922f;
  padding: 12px 45px;
  display: inline-block;
}
.invalid-feedback{
  color:red;
}
